import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { AuthProvider } from './AuthContext';
import Navbar from './components/Navbar';
import CourseList from './components/CourseList';
import ProductDetails from './components/ProductDetails';
import ShoppingCart from './components/ShoppingCart';
import CartDetails from './components/CartDetails';
import Checkout from './components/Checkout';
import PaymentSuccess from './components/PaymentSuccess';
import PasswordResetForm from './components/PasswordResetForm';
import LoginPage from './components/LoginPage';
import PasswordResetRequest from './components/PasswordResetRequest';
import PrivateRoute from './components/PrivateRoute'; // Import the PrivateRoute component
import AccountDetails from './components/AccountDetails';
import ContactUs from './components/ContactUs';
import theme from './theme'; // make sure the path is correct

import { ShoppingCartProvider } from './ShoppingCartContext'; // Import the context provider
import { CouponProvider } from './CouponContext';

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <ShoppingCartProvider>
          <CouponProvider>
            <Router>
              <div>
                <Navbar />
                <Routes>
                  <Route exact path="/" element={<CourseList />} />
                  <Route path="/product/:productId" element={<ProductDetails />} />
                  <Route path="/cart" element={<ShoppingCart />} />
                  <Route path="/cart-details" element={<CartDetails />} />
                  <Route path="/checkout" element={<Checkout />} /> {/* Add the checkout route */}
                  <Route path="/payment-success" element={<PaymentSuccess />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/password-reset/:resetToken" element={<PasswordResetForm />} />
                  <Route path="/request-password-reset" element={<PasswordResetRequest/>} />
                  <Route path="/contact-us" element={<ContactUs />} />

                  {/* Add more routes for categories */}
                  <Route path="/account-details" element={<PrivateRoute><AccountDetails/></PrivateRoute>  } />
                </Routes>
              </div>
            </Router>
          </CouponProvider>
        </ShoppingCartProvider>
      </ThemeProvider>
    </AuthProvider>
    
  );
}

export default App;
