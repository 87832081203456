// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#59892c',
    },
    secondary: {
      main: '#5c2c89',
    },
    analogous1: {
        main: '#88892c',
    },
    analogous2: {
        main: '#2c892e',
    },
    triadic1: {
        main: '#2c895c',
    },
    triadic2: {
        main: '#2c5989'
    }
  },
  // ...you can customize other theme options here
});

export default theme;
