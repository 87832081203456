// src/components/Checkout.js

import React, { useState, useEffect } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useShoppingCart } from '../ShoppingCartContext'; // Import your shopping cart context
import CheckoutForm from './CheckoutForm';
import { TextField, Button, Grid, Paper, Typography, Divider } from '@mui/material';
import { useCoupon } from '../CouponContext';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const Checkout = () => {
  const [clientSecret, setClientSecret] = useState('');
  const [customer, setCustomer] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [submitted, setSubmitted] = useState(false); // To track if the submit button has been clicked
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
  const { state: shoppingCartState } = useShoppingCart(); // Access the shopping cart state
  const { couponCode } = useCoupon();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomer({
      ...customer,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    // Submit the customer information and trigger createPaymentIntent
    try {
      // Set customer info in the state
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting customer information:', error);
    }
  };

  const appearance = {
    theme: 'stripe',
  };

  useEffect(() => {
    if (submitted) {
      // Fetch the payment intent only when submitted
      // Function to create a PaymentIntent with items and customer information
      const createPaymentIntent = async () => {
        try {
          // Extract item IDs from the shopping cart state
          const itemIds = shoppingCartState.cart.map((item) => item.id);

          // Combine customer info with item IDs
          const requestBody = {
            customer,
            items: itemIds,
            couponCode:couponCode
          };

          const response = await fetch(`${API_BASE_URL}/create-payment-intent`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          });

          if (!response.ok) {
            throw new Error('Failed to create PaymentIntent');
          }

          const data = await response.json();
          setClientSecret(data.clientSecret);
        } catch (error) {
          console.error('Error creating PaymentIntent:', error);
        }
      };

      // Call the createPaymentIntent function
      createPaymentIntent();
    }
  }, [shoppingCartState, customer, submitted]);

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Grid container spacing={2} justifyContent="center" style={{ padding: '20px' }}>
      <Grid item xs={12} md={6}>
        <Paper style={{ padding: '20px' }}>
          {!submitted ? (
            <>
              <Typography variant="h6" gutterBottom>
                顾客信息
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="名"
                    variant="outlined"
                    name="firstName"
                    value={customer.firstName}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="姓"
                    variant="outlined"
                    name="lastName"
                    value={customer.lastName}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email - 将用于发送订单确认信息及课程详情"
                    variant="outlined"
                    type="email"
                    name="email"
                    value={customer.email}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setSubmitted(true)}
                        style={{ width: '150px' }}
                      >
                        确认
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container justifyContent='space-between' marginBottom={'10px'}>
                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    顾客信息
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {customer.firstName} {customer.lastName}
                  </Typography>
                  <Typography variant="body1">
                    {customer.email}
                  </Typography>
                </Grid>
              </Grid>
              <Divider></Divider>

            </>
          )}
          <Grid container justifyContent='center' marginTop={'10px'}>
            {clientSecret && submitted && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            )}
          </Grid>

        </Paper>
      </Grid>
    </Grid>
  );

};

export default Checkout;
