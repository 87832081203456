import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, CircularProgress, Button, Grid, CardMedia, Paper, Divider } from '@mui/material';
import { useShoppingCart } from '../ShoppingCartContext';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

function ProductDetails() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const { state, dispatch } = useShoppingCart();

  const addToCart = () => {
    dispatch({ type: 'ADD_TO_CART', payload: product });
  };

  const formatAmount = (amount) => {
    return (amount / 100).toFixed(2);  // Adjust '.toFixed(2)' for your currency format
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/courses/${productId}`);
        setProduct(response.data);
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    }

    fetchData();
  }, [productId]);

  if (!product) {
    return (
      <Container maxWidth="md">
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12} md={6}>
          <CardMedia
            component="img"
            alt={product.title}
            image={product.image_url}
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h4" gutterBottom>
              {product.title}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {product.short_description}
            </Typography>
            <Typography variant="body1" gutterBottom>
              价格: ${formatAmount(product.price)}
            </Typography>
            <ReactMarkdown children={product.long_description} remarkPlugins={[gfm]} />
            <Divider style={{ margin: '20px 0' }} />  {/* Another Divider added here */}
            <Typography gutterBottom>
              购买课程后，课程下载信息将会发送至您在付款时所使用的电子邮箱地址。 <br />
              若成功付款后未收到来自"support@pptfinance.com"的课程详情，请及时联系客服。
            </Typography>
          </Paper>
          {/* Buttons Grid */}
          <Grid container item xs={12} style={{ marginTop: '10px' }} justifyContent="space-between">
            <Grid item>
              <Button variant="outlined" color="secondary" component={Link} to="/">
                回到商店
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={addToCart}>
                加入购物车
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ProductDetails;
