import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';

function PrivateRoute({ children }) {
  const { state } = useAuth();
  const location = useLocation();

  // If the user is authenticated, render the children
  // Otherwise, redirect to the login page with the current location in state
  return state.isAuthenticated ? children : <Navigate to="/login" state={{ from: location }} replace />;
}

export default PrivateRoute;
