import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

function ContactUs() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [submissionMessage, setSubmissionMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await axios.post(`${API_BASE_URL}/contact`, formData);
            setSubmissionMessage(response.data.message);
            setFormData({ name: '', email: '', message: '' }); // Clear the form
        } catch (error) {
            setSubmissionMessage('抱歉，您的信息未能成功发送，请稍后再试。');
            console.error('Error sending message:', error);
        }
        setIsSubmitting(false);
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{ marginTop: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    需要帮助？请联系我们！
                </Typography>
                <form onSubmit={handleSubmit} style={{ width: '100%' }} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="名字"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email地址"
                        name="email"
                        autoComplete="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="message"
                        label="有什么可以帮助您？"
                        type="text"
                        id="message"
                        multiline
                        rows={4}
                        value={formData.message}
                        onChange={handleChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {isSubmitting ? '正在发送...' : '提交'}
                    </Button>
                    {submissionMessage && (
                        <Alert severity="success" sx={{ mt: 2 }}>
                            {submissionMessage}
                        </Alert>
                    )}
                </form>
            </Box>
        </Container>
    );
}

export default ContactUs;
