import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useShoppingCart } from '../ShoppingCartContext';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

function ShoppingCart() {
  const { state } = useShoppingCart();
  const [cartOpen, setCartOpen] = useState(false);

  const toggleCart = () => {
    setCartOpen(!cartOpen);
  };

  const closeCart = () => {
    setCartOpen(false);
  };

  // Ensure that state and state.cart are defined before accessing the cart
  const cartItems = state && state.cart ? state.cart : [];

  return (
    <div>
      <IconButton edge="end" color="inherit" onClick={toggleCart}>
        <ShoppingCartIcon />
      </IconButton>
      <Drawer anchor="right" open={cartOpen} onClose={closeCart}>
        {/* Cart content */}
        <List>
          {cartItems.length === 0 ? (
            <ListItem>
              <ListItemText primary="Your cart is empty." />
            </ListItem>
          ) : (
            cartItems.map((item) => (
              <ListItem key={item.id}>
                <ListItemText primary={item.title} secondary={`Quantity: ${item.quantity}`} />
              </ListItem>
            ))
          )}
        </List>
      </Drawer>
    </div>
  );
}

export default ShoppingCart;
