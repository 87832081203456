// src/components/Navbar.js

import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, IconButton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import { useShoppingCart } from '../ShoppingCartContext';
import { useAuth } from '../AuthContext'; // Import useAuth if you have an AuthContext
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import CompanyIcon from '../assets/200_pixels.png'


// const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

function Navbar() {
  const isMobile = useMediaQuery('(max-width:600px)');
  console.log(isMobile);
  const { state } = useShoppingCart();
  const cartItemCount = state && state.cart ? state.cart.length : 0;
  const { state: authState, dispatch } = useAuth(); // useAuth to check auth state
  const textAuth = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear the authentication token or any auth-related data
    localStorage.removeItem('auth_token');
    // Update the auth state if you're using a context
    if (dispatch) {
      dispatch({ type: 'LOGOUT' });
    }
    // Redirect to home or login page
    navigate('/');
  };

  const isUserLoggedIn = authState && authState.isAuthenticated; // Check if user is logged in

  return (
    <AppBar position="static" sx={{ padding: isMobile ? '8px' : '16px' }}>
      <Toolbar>
      <Typography variant={isMobile ? "subtitle1" : "h6"} component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
  <img src={CompanyIcon} alt="Logo" style={{ width: isMobile ? 24 : 32, height: isMobile ? 24 : 32, marginRight: isMobile ? 0 : 8 }} />
  {!isMobile && 'PPT金融'}
</Typography>
        <Button color="inherit" component={Link} to="/" sx={{ fontSize: isMobile ? 'small' : 'medium' }}>
          { isMobile ?  '课程' : '课程商店'}
        </Button>
        <Button color="inherit" component={Link} to="/account-details" sx={{ fontSize: isMobile ? 'small' : 'medium' }}>
          { isMobile ? '订单' : '订单详情' }
        </Button>
        <Button color="inherit" component={Link} to="/contact-us" sx={{ fontSize: isMobile ? 'small' : 'medium' }}>
          { isMobile ? '帮助' : '联系我们' }
        </Button>
        {/* Add more categories as needed */}

        {/* Update the Link to cart-details */}
        <Link to="/cart-details" style={{ textDecoration: 'none' }}>
        <IconButton edge="end" color="inherit" size={isMobile ? "small" : "medium"}>
  <Badge badgeContent={cartItemCount} color="secondary">
    <ShoppingCartIcon />
  </Badge>
</IconButton>
        </Link>
        {/* Add the Login button */}
        {isUserLoggedIn ? (<Button color="inherit" style={{ textDecoration: 'none' }} sx={{ width: isMobile ? '80px' : '120px', height: isMobile ? '20px' : '30px' }} onClick={handleLogout}>退出登录</Button>
          
        ) : (
          
          <Link to="/login" style={{ textDecoration: 'none' }} sx={{ width: isMobile ? '80px' : '120px', height: isMobile ? '20px' : '30px' }}>
          <Button color="inherit">登录</Button>
        </Link>)}
      </Toolbar>
    </AppBar>
  )
}

export default Navbar;
