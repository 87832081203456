import React, { useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const PasswordResetRequest = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleResetRequest = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/request-password-reset`, {
        email,
      });
      setMessage(response.data.message);
    } catch (error) {
      setError(error.response.data.error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div>
        <Typography variant="h5">Password Reset Request</Typography>
        <form>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleResetRequest}
          >
            Submit
          </Button>
          {error && <Typography color="error">{error}</Typography>}
          {message && <Typography color="success">{message}</Typography>}
        </form>
      </div>
    </Container>
  );
};

export default PasswordResetRequest;
