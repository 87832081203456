// src/components/AccountDetails.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import { Container, Typography, Card, CardContent, List, ListItem, ListItemText, Grid, Button } from '@mui/material';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

function AccountDetails() {
  const { state } = useAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/auth/account-details`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
          }
        });
        setUserInfo(response.data);
        setIsLoading(false);
      } catch (err) {
        setError(err.response?.data?.error || 'Error fetching user info');
        setIsLoading(false);
      }
    };

    if (state.isAuthenticated) {
      fetchUserInfo();
    }
  }, [state.isAuthenticated]);

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>

      </Typography>
      {userInfo ? (
        <>
          <Typography variant="h4">您好, {userInfo.firstName}!</Typography>
          <Typography variant="h6" gutterBottom>
            您的课程
          </Typography>
          {userInfo.purchaseHistory && userInfo.purchaseHistory.length > 0 ? (
            userInfo.purchaseHistory.map((order, index) => (
              <Card key={index} variant="outlined" sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="h6">订单号 {order.id} - 购买日期: {new Date(order.order_date).toLocaleDateString()}</Typography>
                  <List>
                    {order.items.map((item, itemIndex) => (
                      <ListItem key={itemIndex} divider>
                        <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                          <Grid item xs>
                            <ListItemText
                              primary={`课程: ${item.course_name}`}
                            />
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              href={item.gd_link}
                              target="_blank"
                              sx={{ width: '150px', mr: 1 }}
                            >
                              Google Drive
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              href={item.bd_link}
                              target="_blank"
                              sx={{ width: '150px' }}
                            >
                              Baidu Drive
                            </Button>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography>No purchase history available.</Typography>
          )}
        </>
      ) : (
        <Typography>No user information available.</Typography>
      )}
    </Container>
  );
}

export default AccountDetails;
