import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper, Box, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

function PaymentSuccess() {
  const [orderDetails, setOrderDetails] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const clientSecret = queryParams.get('payment_intent_client_secret');
    fetchOrderDetails(clientSecret);
  }, []);

  const fetchOrderDetails = async (clientSecret) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/get-order-details`, {
        clientSecret
      });

      setOrderDetails(response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const formatAmount = (amount) => {
    return (amount / 100).toFixed(2);  // Adjust '.toFixed(2)' for your currency format
  };

  // Updated function to handle PDF download
  const handleDownloadPdf = async () => {
    if (orderDetails) {
      try {
        const pdfFilename = `PPT-Order-${orderDetails.id}-WEB.pdf`; // Assuming the filename format
        const response = await axios.post(`${API_BASE_URL}/get-pdf`, {
          pdf_filename: orderDetails.pdf_name
        }, {
          responseType: 'blob' // Important to handle the binary data correctly
        });

        // Create a URL for the blob
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', pdfFilename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } catch (error) {
        console.error('Error downloading PDF:', error);
      }
    }
  };


  return (
    <Container maxWidth="sm">
      <Box mt={4} mb={2} display="flex" flexDirection="column" alignItems="center">
        <CheckCircleOutlineIcon color="success" sx={{ fontSize: 60 }} />
        <Typography variant="h3" gutterBottom component="div" sx={{ mt: 2 }}>
          支付成功
        </Typography>
        <Paper elevation={3} sx={{ p: 2, mt: 2, width: '100%' }}>
          <Typography variant="body1" gutterBottom>
            谢谢您的惠顾！您的支付已成功。订单信息已经发往至您的邮箱。
          </Typography>
          {orderDetails ? (
            <div> {/* Changed from Typography to div */}
              <Typography variant="body1" gutterBottom>
                订单编号: {orderDetails.id}
              </Typography>
              <Typography variant="body1" gutterBottom>
                订单日期: {orderDetails.order_date}
              </Typography>
              
              <Typography variant="body1" gutterBottom>
                订单状态: {orderDetails.status}
              </Typography>
              <Typography variant="body1" gutterBottom>
                订单明细:
                {orderDetails.items.map((item) => (
                  <Typography key={item.id}>
                    {item.course_name} - 数量: {item.quantity} - 小计: ${formatAmount(item.subtotal)}
                  </Typography>
                ))}
              </Typography>
              {orderDetails.coupon !== 0 && (
                <Typography variant='body1' gutterBottom>
                  优惠明细：折扣{orderDetails.coupon}%
                </Typography>
              )}
              <Typography variant="body1" gutterBottom>
                总金额: ${formatAmount(orderDetails.total_amount)}
              </Typography>
            </div>
          ) : (
            <Typography variant="body1" gutterBottom>
              正在加载订单详情...
            </Typography>
          )}
        </Paper>
        {orderDetails && (
          <Button variant="contained" color="primary" sx={{ mt: 3 }} onClick={handleDownloadPdf}>
            保存订单信息
          </Button>
        )}
      </Box>
    </Container>
  );
  
}

export default PaymentSuccess;
