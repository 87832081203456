import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Container, Typography, TextField, Button, Paper } from '@mui/material';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const PasswordResetForm = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const { resetToken } = useParams();
  console.log (resetToken)

  const handleResetPassword = async () => {
    try {
      if (newPassword !== confirmPassword) {
        setMessage('Passwords do not match');
        return;
      }

      const response = await axios.post(`${API_BASE_URL}/auth/reset-password/${resetToken}`, {
        new_password: newPassword,
      });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response.data.error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ padding: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h5">Reset Your Password</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="New Password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Confirm New Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleResetPassword}
        >
          Reset Password
        </Button>
        {message && (
          <Typography color={message.includes('successful') ? 'primary' : 'error'}>{message}</Typography>
        )}
      </Paper>
    </Container>
  );
};

export default PasswordResetForm;
