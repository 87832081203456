import React, { createContext, useContext, useState } from 'react';

const CouponContext = createContext();

export const useCoupon = () => useContext(CouponContext);

export const CouponProvider = ({ children }) => {
  const [couponCode, setCouponCode] = useState('');

  return (
    <CouponContext.Provider value={{ couponCode, setCouponCode }}>
      {children}
    </CouponContext.Provider>
  );
};
