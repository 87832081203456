import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';

// Define the initial state and reducer for login state management
const initialState = {
  user: null, // User object when logged in, null when logged out
  isAuthenticated: false, // Boolean to check if the user is authenticated
};

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

// Create the AuthContext
const AuthContext = createContext();

// AuthProvider component to wrap the entire app with the context
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('auth_token');
      if (token) {
        try {
          const response = await axios.get(`${API_BASE_URL}/auth/validate-token`, { headers: { 'Authorization': `Bearer ${token}` } });
          // Assuming the response contains user data if the token is valid
          dispatch({ type: 'LOGIN', payload: response.data.user });
        } catch (error) {
          console.error('Token validation failed:', error);
          localStorage.removeItem('auth_token');
          dispatch({ type: 'LOGOUT' });
        }
      }
    };

    validateToken();
  }, []);

  // Define the onLogin function to dispatch the LOGIN action
  const onLogin = (user) => {
    dispatch({ type: 'LOGIN', payload: user });
  };

  // Define the onLogout function to dispatch the LOGOUT action
  const onLogout = () => {
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider value={{ state, onLogin, onLogout, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to access the context
export const useAuth = () => {
  return useContext(AuthContext);
};
