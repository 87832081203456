// src/components/CartDetails.js
import React, { useState } from 'react';
import axios from 'axios'; // Make sure to import axios
import { Container, Divider, Typography, List, ListItem, ListItemText, Grid, IconButton, Button, Alert, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { useShoppingCart } from '../ShoppingCartContext'
import { useCoupon } from '../CouponContext';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

function CartDetails() {
  const { state, dispatch } = useShoppingCart();
  const { couponCode, setCouponCode } = useCoupon();
  const [discountedTotal, setDiscountedTotal] = useState(0);
  const [couponApplied, setCouponApplied] = useState(false);

  const cartItems = state && state.cart ? state.cart : [];
  // Calculate the total amount
  const totalAmount = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  const isCartEmpty = cartItems.length === 0;

  const removeFromCart = (itemId) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: { id: itemId } });
  };

  const handleCouponChange = (event) => {
    setCouponCode(event.target.value);
    if (couponApplied) {
      // Reset to original total if coupon is changed
      setDiscountedTotal(totalAmount);
      setCouponApplied(false);
    }
  };

  const applyCoupon = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/validate-coupon`, {
        couponCode: couponCode,
        totalAmount: totalAmount
      });

      if (response.data && response.data.newTotalAmount) {
        setDiscountedTotal(response.data.newTotalAmount);
        setCouponApplied(true);
      }
    } catch (error) {
      console.error('Error applying coupon:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const formatAmount = (amount) => {
    return (amount / 100).toFixed(2);  // Adjust '.toFixed(2)' for your currency format
  };

  // Display discountedTotal if coupon applied, else totalAmount
  const displayTotal = couponApplied ? discountedTotal : totalAmount;

  return (
    <Container maxWidth="md" style={{ marginTop: '20px' }}>
      <Typography variant="h4" component="div" gutterBottom>
        购物车清单
      </Typography>
      {isCartEmpty ? (
        <Alert severity="info">购物车是空的 - 快去挑选你喜欢的课程吧！</Alert>
      ) : (
        <List>
          {cartItems.map((item) => (
            <ListItem key={item.id}>
              <Grid container alignItems="center">
                <Grid item xs={8}>
                  <ListItemText primary={item.title} secondary={`数量: ${item.quantity}`} />
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2">${formatAmount(item.price) * item.quantity}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton edge="end" color="inherit" onClick={() => removeFromCart(item.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      )}
      <Divider style={{ margin: '20px 0' }} />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <TextField
            label="优惠券代码"
            variant="outlined"
            value={couponCode}
            onChange={handleCouponChange}
            fullWidth
          />
          <Button variant="outlined" color="secondary" onClick={applyCoupon}>
            应用优惠券
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} container justifyContent="flex-end">
          <Typography variant="h6" component="span" style={{ marginRight: '10px' }}>
            总金额: ${formatAmount(displayTotal)}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/checkout"
            disabled={isCartEmpty}
          >
            确认订单
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CartDetails;
