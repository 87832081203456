// src/components/LoginPage.js

import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Import the useAuth hook
import { Container, TextField, Button, Typography, Box } from '@mui/material';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const LoginPage = () => {
  const navigate = useNavigate(); // Create a reference to the current navigation object
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false); // New state for authentication status

  const { onLogin } = useAuth(); // Use the onLogin function from the context

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/login`, {
        email,
        password,
      });
      onLogin(response.data.token);
      localStorage.setItem('auth_token', response.data.token);
      setIsAuthenticated(true); // Redirect to the previous location after logging in
      navigate(-1); // Set authentication status to true
    } catch (error) {
      console.error(error);
      setError(error.response.data.error);
      setIsAuthenticated(false); // Set authentication status to false
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div>
          <Typography variant="h5">
            登录
          </Typography>
          {isAuthenticated ? ( // Conditional rendering based on authentication status
            <p>You are logged in.</p>
          ) : (
            <form>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="密码"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleLogin}
              >
                登录
              </Button>
              <Link
                to="/request-password-reset"
                style={{ textDecoration: 'none' }}
              >
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  style={{ marginTop: '16px' }}
                >
                  重置密码
                </Button>
              </Link>
              {error && <Typography color="error">{error}</Typography>}
            </form>
          )}
        </div>
      </Box>
    </Container>
  );
};

export default LoginPage;
