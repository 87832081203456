// src/components/CourseList.js

import React, { useState, useEffect } from 'react';
import { Container, Grid, Card, CardContent, Typography, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; // Import useHistory from react-router-dom
import axios from 'axios';
import './CourseList.css'; // Import your CSS file
// import BlackFridayBanner from '../assets/black_friday_banner.png';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const CustomCard = styled(Card)(({ theme }) => ({
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.75)',
  },
}));

function CourseList() {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  const handleCardClick = (productId) => {
    // Use the history object to navigate to the product details page
    navigate(`/product/${productId}`);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/courses`); // Fetch data from Flask API
        setCourses(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, []);

  const formatAmount = (amount) => {
    return (amount / 100).toFixed(2);  // Adjust '.toFixed(2)' for your currency format
  };

return (
  <Container maxWidth="lg">
    {/* <img src={BlackFridayBanner} alt="Black Friday Sale" style={{ width: '100%', marginBottom: '20px' }} /> */}
    <Typography variant="h3" component="div" gutterBottom>
    </Typography>
    <Grid container spacing={2}>
      {courses.map((course) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={course.id} onClick={() => handleCardClick(course.id)}>
          <div className='card-container'>
            <CustomCard className='centered-card'>
                <CardMedia
                component="img"
                alt={course.title}
                height="140"
                image={course.image_url}
                />
                <CardContent>
                    <Typography variant="h6" component="div">
                        {course.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" className='description-limiter'>
                        {course.short_description}
                    </Typography>
                    <Typography variant="body1">
                        价格: ${formatAmount(course.price)}
                    </Typography>
                    <Typography variant='body3'>
                      点击查看详情
                    </Typography>
                </CardContent>
            </CustomCard>
          </div>
        </Grid>
      ))}
    </Grid>
  </Container>
);
      }

export default CourseList;
